<div class="favourite">
  <div class="row">
    <div class="col-12 customOverflow">
      <div class="row">
        <div class="col-12">
          <div [ngClass]="{'header': translate.is_arabic, 'headerEn': !translate.is_arabic}">
            <p>{{translate.data.favourite.favourite}}</p>
          </div>
          <div class="body ">
            <div class="container">
              <div class="noData" *ngIf="favourite?.length == 0">
                <h3>{{translate.data.favourite.noFavourite}}</h3>
                <p>{{translate.data.favourite.desc}}</p>
                <button>{{translate.data.favourite.reserve}}</button>
              </div>
              <div  class="karavan-result" >
                <app-karavan></app-karavan>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>