import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import { PagesService} from '../../../pages/pages.service';
import { SharedService } from 'src/app/shared/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from 'src/app/shared/translation.service';

@Component({
  selector: 'app-favourite',
  templateUrl: './favourite.component.html',
  styleUrls: ['./favourite.component.scss']
})
export class FavouriteComponent implements OnInit {

  // favourite data
  favourite:any = [];

  constructor(
    private pagesService: PagesService,
    private route: ActivatedRoute,
    private router: Router,
    public sharedService: SharedService,
    private spinner: NgxSpinnerService,
    public translate: TranslationService,
  ) { }

  ngOnInit(): void {
    // alert("jjjjjjjj");
    //get favourite carvans
    this.spinner.show();
    this.pagesService.getFavouriteCarvans().subscribe(
      (response: any) => {
        this.favourite = {resCarvans:response.data}
        // this.favourite = response.data;
        console.log("favourite carvans",this.favourite);
        this.pagesService.karavans.next(this.favourite);

      },
      (error:any) => {
        console.log(error);
      }

    )
    this.spinner.hide();
  }

}
