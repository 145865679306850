import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {FavouriteComponent} from './favourite.component'

const routes: Routes = [
  {path: '', component: FavouriteComponent ,pathMatch: 'full',},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
exports: [RouterModule]
})

export class FavouriteRoutingModule { }
